import s from './Loader.module.css';

interface Props {
	className?: string;
}

const Loader: React.FC<Props> = ({ className = '' }) => {
	return (
		<div className={`${s.loader} ${className}`}>
			<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
				<circle cx="32" cy="32" r="30.5" stroke="#E5E5E5" strokeWidth="3"/>
				<path d="M62.5 32C62.5 48.8447 48.8447 62.5 32 62.5C15.1553 62.5 1.5 48.8447 1.5 32C1.5 15.1553 15.1553 1.5 32 1.5" stroke="#DBA742" strokeWidth="3" strokeLinecap="round" className={s.path}/>
			</svg>
		</div>
	);
};

export { Loader };
