import React, { Suspense, useCallback } from "react";
import { Switch, Route, Redirect, useLocation, useHistory } from "react-router-dom";
import s from "./App.module.css";
import { Loader } from "./components/Loader";
import { useAppDispatch, useAppSelector } from "./store";
import { initApp, INITIALIZATION_STATUS } from "./store/appSlice";
import { Event } from "./api/types";
import { convertStartDay } from "./helpers/convertStartDay";
import { convertStartTime } from "./helpers/convertStartTime";
import broadcastDefaultImage from "./assets/images/broadcast-default.jpg";

const Highlights = React.lazy(() => import("./pages/Highlights"));
const Broadcasts = React.lazy(() => import("./pages/Broadcasts"));
const Material = React.lazy(() => import("./pages/Material"));
const ContentTemplate = React.lazy(() => import("./components/ContentTemplate"));

export enum ROUTES {
	MATERIAL_ID = '/highlights/:id',
	ROOT = '/',
	BROADCASTS = '/broadcasts',
	BROADCAST = '/broadcasts/:id',
};

function getHighlightVideoAttributes(event: Event) {
	const { attributes } = event;
	return [
		attributes.find(attr => attr.name === 'Турнир'),
		attributes.find(attr => attr.name === 'Стадия турнира'),
		attributes.find(attr => attr.name === 'Тип контента')
	].filter(Boolean as any as <T>(attr: T | undefined) => attr is T);
}

function getBroadcastVideoAttributes(event: Event) {
	const startDay = convertStartDay(event.start);
	const startTime = convertStartTime(event.start);
	return [{
		name: 'Вид спорта',
		value: event.sport
	}, {
		name: 'Время начала',
		value: `${startDay}, ${startTime}`
	}].filter(Boolean);
}

function App() {
	const location = useLocation();
	const dispatch = useAppDispatch();
	const history = useHistory();
	const user = useAppSelector(state => state.data);
	const isAppLoading = useAppSelector((state) => state.app.initialization === INITIALIZATION_STATUS.LOADING);
	const isFetchingEvents = useAppSelector(state => state.events.isFetching);
	const isFetchingAttributes = useAppSelector(state => state.attributes.isFetching);
	const events = useAppSelector(state => state.events.list);
	const [isShowOpacityLoader, setIsShowOpacityLoader] = React.useState((isFetchingEvents || isFetchingAttributes) && Array.isArray(events));
	const handleClickByHighlightRecommendedVideo = useCallback((event) => history.push(`/highlights/${event.id}`), [history]);
	const handleClickByBroadcastRecommendedVideo = useCallback((event) => history.push(`/broadcasts/${event.id}`), [history]);

	React.useEffect(() => {
		dispatch(initApp());
	}, [dispatch]);

	React.useEffect(() => {
		const timeoutId = setTimeout(() => {
			setIsShowOpacityLoader((isFetchingEvents || isFetchingAttributes) && Array.isArray(events));
		}, 100);
		return () => clearTimeout(timeoutId);
	}, [isFetchingEvents, isFetchingAttributes, events]);

	if (isAppLoading) {
		return <Loader />;
	}

	if (!user.highlight && location.pathname === ROUTES.ROOT) {
		return <Redirect to={ROUTES.BROADCASTS}/>;
	}

	return (
		<>
			<Suspense fallback={<Loader />}>
				<Switch>
					<Route path={ROUTES.MATERIAL_ID} exact>
						<ContentTemplate>
							<Material
								backButtonText="Хайлайты"
								getVideoAttributes={getHighlightVideoAttributes}
								recommendationsTitle="Еще видео с этого события"
								backButtonRoute={ROUTES.ROOT}
								onRecommendationClick={handleClickByHighlightRecommendedVideo}
							/>
						</ContentTemplate>
					</Route>

					<Route path={ROUTES.ROOT} exact>
						<ContentTemplate>
							<Highlights />
						</ContentTemplate>
					</Route>

					{user.broadcast && (
						<React.Fragment>
							<Route path={ROUTES.BROADCASTS} exact>
								<ContentTemplate>
									<Broadcasts />
								</ContentTemplate>
							</Route>

							<Route path={ROUTES.BROADCAST} exact>
								<ContentTemplate>
									<Material
										backButtonText="Трансляции"
										getVideoAttributes={getBroadcastVideoAttributes}
										recommendationsTitle="Похожие трансляции"
										backButtonRoute={ROUTES.BROADCASTS}
										onRecommendationClick={handleClickByBroadcastRecommendedVideo}
										isShowRecommendationVideoDate={false}
										isShowRecommendationVideoDuration={false}
										recommendationVideoFallbackImageUrl={broadcastDefaultImage}
									/>
								</ContentTemplate>
							</Route>
						</React.Fragment>
					)}

					<Redirect to={ROUTES.ROOT}/>
				</Switch>
			</Suspense>
			{isShowOpacityLoader && <div className={s['opacity-loader']}><Loader /></div>}
		</>
	);
}

export default App;
