import { REACT_APP_API_ENDPOINT } from "../constants";
import { addSignToRequest, addSignToTelegramBotUrl } from "./addSign";
import { ApiError } from "./types";

export interface DataSport {
	id: number;
	name: string;
}
export interface Data {
	current_customer: {
		id: number;
		name: string;
		last_name: string;
		email: string;
		temporary_password: boolean | null;
		is_editor: boolean;
		can_download: boolean;
		telegram_bot: string;
	};
	highlight?: DataSport[];
	broadcast?: DataSport[];
	sports: DataSport[];
}

export async function fetchData(): Promise<Data | ApiError> {
	const response = await fetch(
		addSignToRequest(`${REACT_APP_API_ENDPOINT}data.json`),
		{credentials: 'include'}
	);
	const result = await response.json();
	const { current_customer } = result;
	if (current_customer) {
		const { telegram_bot, id } = current_customer;
		result.current_customer.telegram_bot = addSignToTelegramBotUrl(telegram_bot, id);
	}
	return result;
}
