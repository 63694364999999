import { Event } from "../api/types";

const monthsSpelling = [
  "Января",
  "Февраля",
  "Марта",
  "Апреля",
  "Мая",
  "Июня",
  "Июля",
  "Августа",
  "Сентября",
  "Октября",
  "Ноября",
  "Декабря"
];

export const convertStartDay = (start: Event['start']) => {
  const date = new Date(start * 1000);
  const day = date.getDate();
  const month = monthsSpelling[date.getMonth()];

  return `${day} ${month}`;
};