import crypto from "crypto-js";

export function addSignToRequest(url: string): string {
	const salt = 'NXS$nUPACV#~}Sb';
	const at = Math.floor(Date.now() / 1000);
	const as = crypto.MD5(`${at}/${salt}`).toString();
	const urlObj = new URL(url);
	urlObj.searchParams.set('at', at.toString());
	urlObj.searchParams.set('as', as);
	return urlObj.toString();
}

export function addSignToTelegramBotUrl(url: string, customerId: number): string {
	const urlObj = new URL(url);
	const salt = 'YHs)xIU!@xLW';
	const md5 = crypto.MD5(`${customerId}/${salt}`).toString();
	urlObj.searchParams.set('start', `${customerId}_${md5}`);
	return urlObj.toString();
}
