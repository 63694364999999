import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchUserData } from "./dataSlice";

export const initApp = createAsyncThunk(
	'app/init',
	async (_, { dispatch }) => await dispatch(fetchUserData())
);

export enum INITIALIZATION_STATUS {
	LOADING = 'loading',
	SUCCESS = 'success'
}

interface State {
	initialization: INITIALIZATION_STATUS;
}

const initialState: State = {
	initialization: INITIALIZATION_STATUS.LOADING,
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(initApp.fulfilled, (state) => {
			state.initialization = INITIALIZATION_STATUS.SUCCESS;
		});
	}
});

export default appSlice.reducer;
