import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Data, fetchData } from "../api/data";

export const successLogin = createAsyncThunk(
	'app/successLogin',
	async (_, { dispatch }) => await dispatch(fetchUserData())
);

export const fetchUserData = createAsyncThunk<Data>(
	'user/fetchUserData',
	async (_, { rejectWithValue }) => {
		try {
			const response = await fetchData();
			if ('error' in response) {
				throw new Error(response.error);
			}
			return response;
		} catch(error) {
			if (error instanceof Error) {
				return rejectWithValue(error.message);
			}
			return rejectWithValue(new Error('Some error'));
		}
	}
);

export const passwordChanged = createAsyncThunk(
	'user/passwordChanged',
	(_, { dispatch }) => dispatch(fetchUserData())
);

interface State {
	current_customer: Partial<Data['current_customer']> | null;
	sports: Data['sports'];
	highlight?: Data['highlight'];
	broadcast?: Data['highlight'];
}

const initialState: State = {
	current_customer: null,
	sports: []
};

export const dataSlice = createSlice({
	name: 'data',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchUserData.fulfilled, (state, action) => {
			state.current_customer = action.payload.current_customer;
			state.sports = action.payload.sports;
			state.highlight = action.payload.highlight;
			state.broadcast = action.payload.broadcast;
		});
		builder.addCase(fetchUserData.rejected, (state) => {
			state.current_customer = initialState.current_customer;
			state.sports = initialState.sports;
			state.highlight = initialState.highlight;
			state.broadcast = initialState.broadcast;
		});
	}
});

export default dataSlice.reducer;
