import { createAsyncThunk } from "@reduxjs/toolkit";
import { Data } from "../../api/data";
import { fetchEventAttributes } from "../../api/event_attributes";
import { RootState } from '..';
import { convertAttributesToObject } from "./attributes.helpers";
import { Attribute, ATTRIBUTE_NAMES, TypesAttributes, PageAttributes } from "./attributes.types";

export const initAttributes = createAsyncThunk(
	'attributes/initAttributes',
	async (payload: TypesAttributes, { getState }) => {
		const state = getState() as RootState;
		const {
			[ATTRIBUTE_NAMES.SPORT_TYPE]: sportTypeAttribute,
			...restAttributes
		} = state.attributes[payload.type].selected;

		const sportId = state.data.sports.find((sport) => sport.name === sportTypeAttribute[0])?.id;

		const attributes = await fetchEventAttributes(
			sportId,
			{
				...restAttributes,
				[ATTRIBUTE_NAMES.SPORT_TYPE]: [] // ставлю равным [] чтобы его значение не применялось, т.к. SPORT_TYPE нужно передавать как id первым параметром
			},
			payload.type
		);

		return {
			attributes,
			type: payload.type
		};
	}
);

export const toggleSelectedAttribute = createAsyncThunk(
	'attributes/toggleSelectedAttribute',
	async (params: { attribute: Attribute } & TypesAttributes, { getState }) => {
		const state = getState() as RootState;
		const { attribute, type } = params;

		const newSelectedAttributes = {...state.attributes[type].selected};

		newSelectedAttributes[attribute.name] = state.attributes[type].selected[attribute.name].includes(attribute.value)
			? state.attributes[type].selected[attribute.name].filter(value => value !== attribute.value)
			: [
				...state.attributes[type].selected[attribute.name],
				attribute.value
			];

		// * Сбросить атрибуты "Команда" и "Стадия турнира", если был переключен "Турнир"
		if (attribute.name === ATTRIBUTE_NAMES.TOURNAMENT) {
			newSelectedAttributes[ATTRIBUTE_NAMES.TOURNAMENT_STAGES] = [];
			newSelectedAttributes[ATTRIBUTE_NAMES.TEAM] = [];
		}

		// * Сбросить "Момент", если был переключен "Тип контента (Хайлайты/Обзоры)"
		if (attribute.name === ATTRIBUTE_NAMES.CONTENT_TYPE) {
			newSelectedAttributes[ATTRIBUTE_NAMES.MOMENT] = [];
		}

		const {
			[ATTRIBUTE_NAMES.SPORT_TYPE]: [sportTypeAttribute],
			...selectedAttributesWithoutSportType
		} = newSelectedAttributes;

		const sportId = state.data.sports.find(sport => sport.name === sportTypeAttribute)?.id;

		const newAttributes = await fetchEventAttributes(
			sportId,
			{
				...selectedAttributesWithoutSportType,
				[ATTRIBUTE_NAMES.SPORT_TYPE]: [] // ставлю равным [] чтобы его значение не применялось, т.к. SPORT_TYPE нужно передавать как id первым параметром
			},
			params.type
		);

		const convertedNewAttributes = convertAttributesToObject(newAttributes);
		const comparedSelectedAttributes: PageAttributes[typeof type] = {
			[ATTRIBUTE_NAMES.YEAR]: convertedNewAttributes[ATTRIBUTE_NAMES.YEAR]?.filter(value => newSelectedAttributes[ATTRIBUTE_NAMES.YEAR].includes(value)) ?? [],
			[ATTRIBUTE_NAMES.CONTENT_TYPE]: convertedNewAttributes[ATTRIBUTE_NAMES.CONTENT_TYPE]?.filter(value => newSelectedAttributes[ATTRIBUTE_NAMES.CONTENT_TYPE].includes(value)) ?? [],
			[ATTRIBUTE_NAMES.SPORT_TYPE]: state.attributes[type].selected[ATTRIBUTE_NAMES.SPORT_TYPE],
			[ATTRIBUTE_NAMES.MOMENT]: convertedNewAttributes[ATTRIBUTE_NAMES.MOMENT]?.filter(value => newSelectedAttributes[ATTRIBUTE_NAMES.MOMENT].includes(value)) ?? [],
			[ATTRIBUTE_NAMES.TOURNAMENT]: convertedNewAttributes[ATTRIBUTE_NAMES.TOURNAMENT]?.filter(value => newSelectedAttributes[ATTRIBUTE_NAMES.TOURNAMENT].includes(value)) ?? [],
			[ATTRIBUTE_NAMES.TEAM]: convertedNewAttributes[ATTRIBUTE_NAMES.TEAM]?.filter(value => newSelectedAttributes[ATTRIBUTE_NAMES.TEAM].includes(value)) ?? [],
			[ATTRIBUTE_NAMES.TOURNAMENT_STAGES]: convertedNewAttributes[ATTRIBUTE_NAMES.TOURNAMENT_STAGES]?.filter(value => newSelectedAttributes[ATTRIBUTE_NAMES.TOURNAMENT_STAGES].includes(value)) ?? []
		};
		return {
			...state.attributes,
			type,
			[type]: {
				attributes: convertedNewAttributes,
				selected: comparedSelectedAttributes
			}
		};
	}
);

export const resetSelectedAttributes = createAsyncThunk(
	'attributes/resetSelectedAttributes',
	async ({ type }: TypesAttributes, {getState}) => {
		const state = getState() as RootState;

		const newSelectedAttributes = {
			[ATTRIBUTE_NAMES.YEAR]: [],
			[ATTRIBUTE_NAMES.CONTENT_TYPE]: [],
			[ATTRIBUTE_NAMES.SPORT_TYPE]: [],
			[ATTRIBUTE_NAMES.MOMENT]: [],
			[ATTRIBUTE_NAMES.TOURNAMENT]: [],
			[ATTRIBUTE_NAMES.TEAM]: [],
			[ATTRIBUTE_NAMES.TOURNAMENT_STAGES]: []
		};

		const {
			[ATTRIBUTE_NAMES.SPORT_TYPE]: [selectedSportType],
			...selectedAttributesWithoutSportType
		} = newSelectedAttributes;

		const sportId = state.data.sports.find(sport => sport.name === selectedSportType)?.id;

		// SPORT_TYPE ставлю равным [] чтобы его значение не применялось, тк SPORT_TYPE нужно передавать как id первым параметром
		const newAttributes = await fetchEventAttributes(
			sportId,
			{
				...selectedAttributesWithoutSportType,
				[ATTRIBUTE_NAMES.SPORT_TYPE]: []
			},
			type
		);

		const convertedNewAttributes = convertAttributesToObject(newAttributes);

		return {
			...state.attributes,
			type,
			[type]: {
				attributes: convertedNewAttributes,
				selected: newSelectedAttributes
			}
		};
	}
);

export const resetSelectedAttribute = createAsyncThunk(
	'attributes/resetSelectedAttribute',
	async (params: {name: ATTRIBUTE_NAMES} & TypesAttributes, ThunkAPI) => {
		const state = ThunkAPI.getState() as RootState;
		const { name, type } = params;

		const newSelectedAttributes = {...state.attributes[type].selected};
		newSelectedAttributes[name] = [];

		// * Скидывает указанные атрибуты, если меняется "Спорт"
		if (name === ATTRIBUTE_NAMES.SPORT_TYPE) {
			newSelectedAttributes[ATTRIBUTE_NAMES.MOMENT] = [];
			newSelectedAttributes[ATTRIBUTE_NAMES.TOURNAMENT] = [];
			newSelectedAttributes[ATTRIBUTE_NAMES.TOURNAMENT_STAGES] = [];
			newSelectedAttributes[ATTRIBUTE_NAMES.TEAM] = [];
		}

		// * Скинуть "Момент", если был выбран "Тип контента"
		if (name === ATTRIBUTE_NAMES.CONTENT_TYPE) {
			newSelectedAttributes[ATTRIBUTE_NAMES.MOMENT] = [];
		}

		if (name === ATTRIBUTE_NAMES.TOURNAMENT) {
			newSelectedAttributes[ATTRIBUTE_NAMES.TOURNAMENT_STAGES] = [];
			newSelectedAttributes[ATTRIBUTE_NAMES.TEAM] = [];
		}
		const {
			[ATTRIBUTE_NAMES.SPORT_TYPE]: [selectedSportType],
			...selectedAttributesWithoutSportType
		} = newSelectedAttributes;

		const sportId = state.data.sports.find(sport => sport.name === selectedSportType)?.id;

		// SPORT_TYPE ставлю равным [] чтобы его значение не применялось, тк SPORT_TYPE нужно передавать как id первым параметром
		const newAttributes = await fetchEventAttributes(
			sportId,
			{
				...selectedAttributesWithoutSportType,
				[ATTRIBUTE_NAMES.SPORT_TYPE]: []
			},
			params.type
		);

		const convertedNewAttributes = convertAttributesToObject(newAttributes);

		const comparedSelectedAttributes: PageAttributes[typeof type] = {
			[ATTRIBUTE_NAMES.YEAR]: convertedNewAttributes[ATTRIBUTE_NAMES.YEAR]?.filter(value => newSelectedAttributes[ATTRIBUTE_NAMES.YEAR].includes(value)) ?? [],
			[ATTRIBUTE_NAMES.CONTENT_TYPE]: convertedNewAttributes[ATTRIBUTE_NAMES.CONTENT_TYPE]?.filter(value => newSelectedAttributes[ATTRIBUTE_NAMES.CONTENT_TYPE].includes(value)) ?? [],
			[ATTRIBUTE_NAMES.SPORT_TYPE]: newSelectedAttributes[ATTRIBUTE_NAMES.SPORT_TYPE],
			[ATTRIBUTE_NAMES.MOMENT]: convertedNewAttributes[ATTRIBUTE_NAMES.MOMENT]?.filter(value => newSelectedAttributes[ATTRIBUTE_NAMES.MOMENT].includes(value)) ?? [],
			[ATTRIBUTE_NAMES.TOURNAMENT]: convertedNewAttributes[ATTRIBUTE_NAMES.TOURNAMENT]?.filter(value => newSelectedAttributes[ATTRIBUTE_NAMES.TOURNAMENT].includes(value)) ?? [],
			[ATTRIBUTE_NAMES.TEAM]: convertedNewAttributes[ATTRIBUTE_NAMES.TEAM]?.filter(value => newSelectedAttributes[ATTRIBUTE_NAMES.TEAM].includes(value)) ?? [],
			[ATTRIBUTE_NAMES.TOURNAMENT_STAGES]: convertedNewAttributes[ATTRIBUTE_NAMES.TOURNAMENT_STAGES]?.filter(value => newSelectedAttributes[ATTRIBUTE_NAMES.TOURNAMENT_STAGES].includes(value)) ?? []
		};

		return {
			...state.attributes,
			type,
			[type]: {
				attributes: convertedNewAttributes,
				selected: comparedSelectedAttributes
			}
		};
	}
);

export const changeSportType = createAsyncThunk(
	'attributes/changeSportType',
	async (params: {sport: Data['sports'][0]} & TypesAttributes, ThunkAPI) => {
		const state = ThunkAPI.getState() as RootState;
		const { type, sport } = params;

		const newSelectedAttributes: PageAttributes[typeof type] = {
			...state.attributes[type].selected,
			[ATTRIBUTE_NAMES.MOMENT]: [],
			[ATTRIBUTE_NAMES.TOURNAMENT]: [],
			[ATTRIBUTE_NAMES.TOURNAMENT_STAGES]: [],
			[ATTRIBUTE_NAMES.TEAM]: []
		};

		const {
			[ATTRIBUTE_NAMES.SPORT_TYPE]: _,
			...selectedAttributesWithoutSportType
		} = newSelectedAttributes;

		const sportId = sport.id;

		const newAttributes = await fetchEventAttributes(
			sportId,
			{
				...selectedAttributesWithoutSportType,
				[ATTRIBUTE_NAMES.SPORT_TYPE]: [] // ставлю равным [] чтобы его значение не применялось, тк SPORT_TYPE нужно передавать как id первым параметром
			},
			params.type
		);

		const convertedNewAttributes = convertAttributesToObject(newAttributes);

		const comparedSelectedAttributes: PageAttributes[typeof type] = {
			[ATTRIBUTE_NAMES.YEAR]: convertedNewAttributes[ATTRIBUTE_NAMES.YEAR]?.filter(value => newSelectedAttributes[ATTRIBUTE_NAMES.YEAR].includes(value)) ?? [],
			[ATTRIBUTE_NAMES.CONTENT_TYPE]: convertedNewAttributes[ATTRIBUTE_NAMES.CONTENT_TYPE]?.filter(value => newSelectedAttributes[ATTRIBUTE_NAMES.CONTENT_TYPE].includes(value)) ?? [],
			[ATTRIBUTE_NAMES.SPORT_TYPE]: [sport.name],
			[ATTRIBUTE_NAMES.MOMENT]: [],
			[ATTRIBUTE_NAMES.TOURNAMENT]: [],
			[ATTRIBUTE_NAMES.TEAM]: [],
			[ATTRIBUTE_NAMES.TOURNAMENT_STAGES]: []
		};

		return {
			...state.attributes,
			type,
			[type]: {
				attributes: convertedNewAttributes,
				selected: comparedSelectedAttributes
			}
		};
	}
);
