import { RootState } from '..';
import { EventAttribute, fetchEventAttributes } from '../../api/event_attributes';

import { ATTRIBUTE_NAMES, PageAttributes } from './attributes.types';

export function convertAttributesToObject(attributes: EventAttribute[]) {
	return attributes.reduce((prevValue, attribute) => {
		prevValue[attribute.name as ATTRIBUTE_NAMES] = attribute.values;
		return prevValue;
	}, {} as PageAttributes['highlight' | 'broadcast']);
}

export async function fetchEventAttributesWrapper(state: RootState): Promise<EventAttribute[]> {
	const {
		[ATTRIBUTE_NAMES.SPORT_TYPE]: sportTypeAttribute,
		...restAttributes
	} = state.attributes.highlight.selected;

	const sportId = state.data.sports.find(sport => sport.name === sportTypeAttribute[0])?.id;

	// SPORT_TYPE ставлю равным [] чтобы его значение не применялось, тк SPORT_TYPE нужно передавать как id первым параметром
	return await fetchEventAttributes(
		sportId,
		{
			...restAttributes,
			[ATTRIBUTE_NAMES.SPORT_TYPE]: []
		},
	);
}
