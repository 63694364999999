export enum ATTRIBUTE_NAMES {
	YEAR = 'Год',
	CONTENT_TYPE = 'Тип контента',
	SPORT_TYPE = 'Вид спорта',
	MOMENT = 'Момент',
	TOURNAMENT = 'Турнир',
	TEAM = 'Команда',
	TOURNAMENT_STAGES = 'Стадия турнира'
};

export interface PageAttributes {
	highlight: {
		[ATTRIBUTE_NAMES.YEAR]: string[];
		[ATTRIBUTE_NAMES.CONTENT_TYPE]: string[];
		[ATTRIBUTE_NAMES.SPORT_TYPE]: string[];
		[ATTRIBUTE_NAMES.MOMENT]: string[];
		[ATTRIBUTE_NAMES.TOURNAMENT]: string[];
		[ATTRIBUTE_NAMES.TEAM]: string[];
		[ATTRIBUTE_NAMES.TOURNAMENT_STAGES]: string[];
	};
	broadcast: {
		[ATTRIBUTE_NAMES.YEAR]: string[];
		[ATTRIBUTE_NAMES.CONTENT_TYPE]: string[];
		[ATTRIBUTE_NAMES.SPORT_TYPE]: string[];
		[ATTRIBUTE_NAMES.MOMENT]: string[];
		[ATTRIBUTE_NAMES.TOURNAMENT]: string[];
		[ATTRIBUTE_NAMES.TEAM]: string[];
		[ATTRIBUTE_NAMES.TOURNAMENT_STAGES]: string[];
	};
};

export interface AttributesState {
	isFetching: boolean;
	highlight: {
		attributes: PageAttributes['highlight'];
		selected: PageAttributes['highlight'];
	};
	broadcast: {
		attributes: PageAttributes['broadcast'];
		selected: PageAttributes['broadcast'];
	};
}

export interface Attribute {
	name: ATTRIBUTE_NAMES;
	value: string;
}

export type TypesAttributes = {
	type: keyof Omit<AttributesState, 'isFetching'>;
};

export type BroadcastType = 'future' | 'past';
