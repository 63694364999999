import { createSlice } from '@reduxjs/toolkit';
import { convertAttributesToObject } from './attributes.helpers';
import { AttributesState, ATTRIBUTE_NAMES } from './attributes.types';
import {
	changeSportType,
	initAttributes,
	resetSelectedAttribute,
	resetSelectedAttributes,
	toggleSelectedAttribute,
} from "./attributes.thunks";

const initialState: AttributesState = {
	isFetching: false,
	highlight: {
		attributes: {
			[ATTRIBUTE_NAMES.YEAR]: [],
			[ATTRIBUTE_NAMES.CONTENT_TYPE]: [],
			[ATTRIBUTE_NAMES.SPORT_TYPE]: [],
			[ATTRIBUTE_NAMES.MOMENT]: [],
			[ATTRIBUTE_NAMES.TOURNAMENT]: [],
			[ATTRIBUTE_NAMES.TEAM]: [],
			[ATTRIBUTE_NAMES.TOURNAMENT_STAGES]: []
		},
		selected: {
			[ATTRIBUTE_NAMES.YEAR]: [],
			[ATTRIBUTE_NAMES.CONTENT_TYPE]: [],
			[ATTRIBUTE_NAMES.SPORT_TYPE]: [],
			[ATTRIBUTE_NAMES.MOMENT]: [],
			[ATTRIBUTE_NAMES.TOURNAMENT]: [],
			[ATTRIBUTE_NAMES.TEAM]: [],
			[ATTRIBUTE_NAMES.TOURNAMENT_STAGES]: []
		}
	},
	broadcast: {
		attributes: {
			[ATTRIBUTE_NAMES.YEAR]: [],
			[ATTRIBUTE_NAMES.CONTENT_TYPE]: [],
			[ATTRIBUTE_NAMES.SPORT_TYPE]: [],
			[ATTRIBUTE_NAMES.MOMENT]: [],
			[ATTRIBUTE_NAMES.TOURNAMENT]: [],
			[ATTRIBUTE_NAMES.TEAM]: [],
			[ATTRIBUTE_NAMES.TOURNAMENT_STAGES]: []
		},
		selected: {
			[ATTRIBUTE_NAMES.YEAR]: [],
			[ATTRIBUTE_NAMES.CONTENT_TYPE]: [],
			[ATTRIBUTE_NAMES.SPORT_TYPE]: [],
			[ATTRIBUTE_NAMES.MOMENT]: [],
			[ATTRIBUTE_NAMES.TOURNAMENT]: [],
			[ATTRIBUTE_NAMES.TEAM]: [],
			[ATTRIBUTE_NAMES.TOURNAMENT_STAGES]: []
		}
	}
};

export const attributesSlice = createSlice({
	name: 'attributes',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(initAttributes.pending, state => {
				state.isFetching = true;
			})
			.addCase(initAttributes.fulfilled, (state, action) => {
				const fetchedAttributes = convertAttributesToObject(action.payload.attributes);
				Object.keys(fetchedAttributes).forEach(attributeName => {
					state[action.payload.type].attributes[attributeName as ATTRIBUTE_NAMES.SPORT_TYPE | ATTRIBUTE_NAMES.TOURNAMENT] = fetchedAttributes[attributeName as ATTRIBUTE_NAMES] ?? [];
				});
				state.isFetching = false;
			});

		builder
			.addCase(toggleSelectedAttribute.pending, state => {
				state.isFetching = true;
			})
			.addCase(toggleSelectedAttribute.fulfilled, (state, action) => {
				const { type } = action.payload;
				const { attributes, selected } = action.payload[type];
				state[type].attributes = attributes;
				state[type].selected = selected;
				state.isFetching = false;
			});
		builder
			.addCase(changeSportType.pending, state => {
				state.isFetching = true;
			})
			.addCase(changeSportType.fulfilled, (state, action) => {
				const { type } = action.payload;
				const { attributes, selected } = action.payload[type];
				state[type].attributes = attributes;
				state[type].selected = selected;
				state.isFetching = false;
			});
		builder
			.addCase(resetSelectedAttribute.pending, state => {
				state.isFetching = true;
			})
			.addCase(resetSelectedAttribute.fulfilled, (state, action) => {
				const { type } = action.payload;
				const { attributes, selected } = action.payload[type];
				state[type].attributes = attributes;
				state[type].selected = selected;
				state.isFetching = false;
			});
		builder
			.addCase(resetSelectedAttributes.pending, state => {
				state.isFetching = true;
			})
			.addCase(resetSelectedAttributes.fulfilled, (state, action) => {
				const { type } = action.payload;
				const { attributes, selected } = action.payload[type];
				state[type].attributes = attributes;
				state[type].selected = selected;
				state.isFetching = false;
			});
	}
});

export default attributesSlice.reducer;
