import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import { fetchEvents, FetchEventsSuccess } from "../api/events";
import { Event } from "../api/types";
import { AttributesState, ATTRIBUTE_NAMES, BroadcastType, TypesAttributes } from "./attributes/attributes.types";

export type SearchQuery = string;

type State = {
	isFetching: boolean;
	count: number;
	list: Event[] | null;
	page: number;
	searchQuery: SearchQuery;
	broadcastActiveTab: BroadcastType;
};

const initialState: State = {
	isFetching: false,
	count: 0,
	list: null,
	page: 1,
	searchQuery: '',
	broadcastActiveTab: 'future'
};

export const applySearchQuery = createAsyncThunk(
	'events/applySearchQuery',
	async ({ searchQuery, type }: {searchQuery: string; type: TypesAttributes['type']}, {getState, rejectWithValue}) => {
		try {
			const state = getState() as RootState;
			const selectedAttributes = state.attributes.highlight.selected;
			const sportId = state.data.sports.find(sport => sport.name === selectedAttributes[ATTRIBUTE_NAMES.SPORT_TYPE]?.[0])?.id;
			const {[ATTRIBUTE_NAMES.SPORT_TYPE]: _ , ...attributes} = selectedAttributes;
			const response = await fetchEvents({
				search: searchQuery,
				page: 1,
				attributes: (attributes as any),
				sportId,
				type
			});
			if ('error' in response) {
				throw new Error(response.error);
			}
			return {
				...response,
				page: 1,
				searchQuery
			};
		} catch(error) {
			if (error instanceof Error) {
				return rejectWithValue(error.message);
			}
			return rejectWithValue(new Error('Some error'));
		}
	}
);

export const fetchNextPage = createAsyncThunk<{ count: number; events: Event[]; page: number }, TypesAttributes & { filterByType?: BroadcastType }>(
	'events/fetchNextPage',
	async (payload, {getState, rejectWithValue}) => {
		try {
			const state = getState() as RootState;
			const {page, searchQuery, list} = state.events;
			const selectedAttributes: AttributesState[TypesAttributes['type']]['selected'] = state.attributes[payload.type].selected;
			const sportId = state.data.sports.find(sport => sport.name === selectedAttributes[ATTRIBUTE_NAMES.SPORT_TYPE]?.[0])?.id;
			const {[ATTRIBUTE_NAMES.SPORT_TYPE]: _ , ...attributes} = selectedAttributes;
			const nextPage = page + 1;
			const response = await fetchEvents({
				search: searchQuery,
				page: nextPage,
				attributes: (attributes as any),
				sportId,
				type: payload.type,
				filterByType: payload.filterByType
			});
			if ('error' in response) {
				throw new Error(response.error);
			}
			return {
				count: response.count,
				events: [...(list ?? []), ...response.events],
				page: nextPage
			};
		} catch(error) {
			if (error instanceof Error) {
				return rejectWithValue(error.message);
			}
			return rejectWithValue(new Error('Some error'));
		}
	}
);

export const fetchEventsThunk = createAsyncThunk<FetchEventsSuccess, TypesAttributes & { filterByType?: BroadcastType }>(
	'events/fetchEventsThunk',
	async ({ type, filterByType }, { getState, rejectWithValue }) => {
		try {
			const state = getState() as RootState;
			const {searchQuery} = state.events;
			const selectedAttributes = state.attributes[type].selected;

			const sportId = state.data[type]?.find(sport => sport.name === selectedAttributes[ATTRIBUTE_NAMES.SPORT_TYPE]?.[0])?.id;
			const {[ATTRIBUTE_NAMES.SPORT_TYPE]: _ , ...attributes} = selectedAttributes;

			const response = await fetchEvents({
				search: searchQuery,
				page: 1,
				attributes: (attributes as any),
				sportId,
				type,
				filterByType
			});

			if ('error' in response) {
				throw new Error(response.error);
			}
			return response;
		} catch(error) {
			if (error instanceof Error) {
				return rejectWithValue(error.message);
			}
			return rejectWithValue(new Error('Some error'));
		}
	}
);

export const eventsSlice = createSlice({
	name: 'events',
	initialState,
	reducers: {
		resetEvents: (state) => {
			state.page = 1;
			state.list = [];
		},
		setBroadcastActiveTab: (state, action: PayloadAction<BroadcastType>) => {
			state.broadcastActiveTab = action.payload;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(applySearchQuery.pending, state => {
				state.isFetching = true;
			})
			.addCase(applySearchQuery.fulfilled, (state, action) => {
				const {count, events, page, searchQuery} = action.payload as {
					count: State['count'];
					events: State['list'];
					page: State['page'];
					searchQuery: State['searchQuery'];
				};
				state.count = count;
				state.list = events;
				state.page = page;
				state.searchQuery = searchQuery;
				state.isFetching = false;
			});
		builder
			.addCase(fetchNextPage.pending, state => {
				// state.isFetching = true;
			})
			.addCase(fetchNextPage.fulfilled, (state, action) => {
				const {events, page} = action.payload;
				state.list = events;
				state.page = page;
				// state.isFetching = false;
			});
		builder
			.addCase(fetchEventsThunk.pending, state => {
				state.isFetching = true;
				state.count = 0;
				state.list = null;
				state.page = 1;
			})
			.addCase(fetchEventsThunk.fulfilled, (state, action) => {
				const {count, events} = action.payload;
				state.count = count;
				state.list = events;
				state.page = 1;
				state.isFetching = false;
			});
	}
});

export const { resetEvents, setBroadcastActiveTab } = eventsSlice.actions;

export default eventsSlice.reducer;
