import { REACT_APP_API_ENDPOINT } from "../constants";
import { BroadcastType, TypesAttributes } from "../store/attributes/attributes.types";
import { addSignToRequest } from "./addSign";
import { ApiError, Event } from "./types";

type Props = {
	sportId?: number;
	page?: number;
	perPage?: number;
	search?: string;
	attributes?: {
		[key: string]: string[];
	};
	filterByType?: BroadcastType;
} & Partial<TypesAttributes>;

export interface FetchEventsSuccess {
	count: number;
	events: Event[];
}

export async function fetchEvents({ page, perPage, search, attributes, sportId, type, filterByType }: Props = {}): Promise<FetchEventsSuccess | ApiError> {
	const url = new URL(addSignToRequest(`${REACT_APP_API_ENDPOINT}events.json`));
	if (sportId) {
		url.searchParams.set('sport_id', sportId.toString());
	}
	if (page) {
		url.searchParams.set('page', page.toString());
	}
	if (perPage) {
		url.searchParams.set('per_page', perPage.toString());
	}
	if (search && type === 'highlight') {
		url.searchParams.set('search', search);
	}
	if (attributes) {
		Object.keys(attributes).forEach(
			key => attributes[key].forEach(
				attribute => url.searchParams.append(`attributes[${key}][]`, attribute)
			)
		);
	}
	if (type === 'broadcast') {
		url.searchParams.set('broadcast', filterByType ?? 'true');
	}

	const response = await fetch(
		url.toString(),
		{ credentials: 'include'}
	);
	const result = await response.json();
	return result;
}
