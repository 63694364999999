import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import appSlice from "./appSlice";
import dataReducer from "./dataSlice";
import attributesReducer from "./attributes/attributes.slice";
import eventsReducer from "./eventsSlice";

export const store = configureStore({
	reducer: {
		app: appSlice,
		data: dataReducer,
		attributes: attributesReducer,
		events: eventsReducer
	}
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
