import { REACT_APP_API_ENDPOINT } from "../constants";
import { ATTRIBUTE_NAMES, PageAttributes, TypesAttributes } from "../store/attributes/attributes.types";
import { addSignToRequest } from "./addSign";

export interface EventAttribute {
	name: ATTRIBUTE_NAMES;
	values: string[];
}

export async function fetchEventAttributes(
	sportId?: number,
	attributes?: PageAttributes['highlight' | 'broadcast'],
	type?: TypesAttributes['type']
): Promise<EventAttribute[]> {
	const url = new URL(addSignToRequest(`${REACT_APP_API_ENDPOINT}event_attributes.json`));

	if (sportId) {
		url.searchParams.set('sport_id', sportId.toString());
	}

	if (attributes) {
		Object.keys(attributes).forEach(attributeName => {
			attributes[attributeName as ATTRIBUTE_NAMES].forEach(attributeValue => {
				url.searchParams.append(`attributes[${attributeName}][]`, attributeValue);
			});
		});
	}

	if (type === 'broadcast') {
		url.searchParams.set('broadcast', 'true');
	}

	const response = await fetch(
		url.toString(),
		{credentials: 'include'}
	);

	return await response.json();
}
